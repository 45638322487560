import WaveModel from '../models/data_models/WaveModel';
import CardModel from '../models/component_models/CardModel';
import EntityUtils from './EntityUtils';
import TreeViewModel from '../models/component_models/TreeViewModel';

export default class WaveUtils {
    static createCardData(waveDict) {
        const wave = new WaveModel(waveDict);
        return new CardModel({
            id: wave.id,
            title: wave.name,
            width: 250,
            sections: [
                ...['id', 'descriptionKey', 'priority', 'duration'].map(
                    (key) => ({
                        type: 'value',
                        title: wave.titles[key],
                        content: {
                            value: wave[key].toString(),
                        },
                    })
                ),
            ],
        });
    }

    static createSmallCardData(waveDict) {
        const wave = new WaveModel(waveDict);
        return new CardModel({
            id: wave.id,
            title: wave.name,
            sections: [
                ...['id', 'priority'].map((key) => ({
                    type: 'value',
                    title: wave.titles[key],
                    content: {
                        value: wave[key].toString(),
                    },
                })),
            ],
        });
    }

    static createCardListData(waves) {
        return waves.map((waveDict) => this.createSmallCardData(waveDict));
    }

    static createTreeViewNodes(waveDict) {
        const wave = new WaveModel(waveDict);
        return [
            {
                title: wave.titles.waveEntities,
                type: 'gallery',
                content: {
                    data: wave.waveEntities.map((waveEntity) => ({
                        ...waveEntity.entity,
                        count: waveEntity.count,
                    })),
                    mapper: EntityUtils,
                },
            },
        ];
    }

    static createTreeViewData(waveDict) {
        const waveCardData = this.createCardData(waveDict);
        return new TreeViewModel({
            root: {
                type: 'card',
                content: waveCardData,
            },
            nodes: this.createTreeViewNodes(waveDict),
        });
    }
}
